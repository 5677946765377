<template>
  <div class="load-user-data">
    <h3 class="mt-4 mb-4">Loading Your Data</h3>
    {{ status_message }} 
    <b-progress :value="progress" max="100" show-progress animated></b-progress>
    <b-modal id="identity-modal" @ok="onModelSetIdentity" title="Choose Identity">
      <p>Multiple identities were found for this e-mail address.
        Please choose an identity to proceed. If you are an Operator, you
        should select your name.
      </p>
      <b-form-select v-model="identity_selected" :options="identity_options"></b-form-select>
    </b-modal>
  </div>
</template>
<script>
import * as idb from "idb-keyval"
import { SessionMixin } from '../mixins'

export default {
  name: "LoadUserData",
  mixins: [SessionMixin],
  async mounted() {
    console.log("LoadUserData mounted.")
    await this.getSession()
    this.progress = 25
    if (this.signed_in) {
      console.log('signed in already')
      this.identities = await this.getIdentities()
      console.log(this.identities)
      const identity_count = Object.keys(this.identities).length
      console.log('identity count: ' + identity_count)
      if (identity_count == 1) {
        const sdwis_id = Object.keys(this.identities)[0]
        await this.processUser(sdwis_id, false)
      }
      else if (identity_count > 1) {
        // check for preference
        await this.$store.commit("set_switch_user", true)
        const user = this.$store.state.user
        const sdwis_id = await idb.get(user.user_id + '_identity')
        if (sdwis_id && this.identities[sdwis_id]) {
          await this.processUser(sdwis_id, false)
        }
        else {
          Object.values(this.identities).forEach(identity => {
            console.log(identity)
            this.identity_options.push(
              { value: identity.sdwis_id, text: identity.name }
            )
          })
          this.$bvModal.show('identity-modal')
        }
      }
      else {
        // MSDH
        console.log('0 idents - MSDH')
        this.progress = 50
        await this.checkSigningKey()
        this.progress = 75
        await this.setDefaultPWS()
        this.progress = 100
        this.$router.push({ name: "Home" })
      }
    }
    else {
      console.log('not signed in - routing back home')
      this.progress = 100
      this.$router.push({ name: "Home" })
    }
  },
  data() {
    return {
      progress: 0,
      status_message: 'loading',
      identities: {},
      identity_options: [{ value: null, text: 'Choose an Identity'}],
      identity_selected: null,
    }
  },
  methods: {
    async onModelSetIdentity() {
      const sdwis_id = this.identity_selected
      await this.processUser(sdwis_id, true)
    },
    async setIdentity(sdwis_id, remember) {
      // remember only applies to multiple idents
      let identity = this.identities[sdwis_id]
      console.log('chose identity')
      console.log(identity)
      let user = this.$store.state.user
      user.sdwis_id = sdwis_id
      user.first_name = identity.first_name
      user.last_name = identity.last_name
      await this.$store.commit("set_user", user)
      if (remember) {
        const key = user.user_id + '_identity'
        await idb.set(key, this.identity_selected)
      }
    },
    async processUser(sdwis_id, remember_identity) {
      console.log('calling process user')
      await this.setIdentity(sdwis_id, remember_identity)
      this.progress = 50
      await this.checkSigningKey()
      this.progress = 75
      await this.getPWS(sdwis_id)
      await this.setDefaultPWS()
      this.progress = 100
      this.$router.push({ name: "Home" })
    },
    async getSession() {
      this.status_message = 'Checking Session'
      console.log("checking session")
      const path = process.env.VUE_APP_API_URI + "/getsession"
      try {
        const res = await this.axios.get(path, { withCredentials: true }) 
        console.log(res)
        if (res.data.user) {
          this.$store.commit("set_user", res.data.user)
          this.$store.commit("set_role", res.data.user.roles[0])
          this.$store.commit("set_signed_in", true)
          // set default PWS
        } else {
          console.log("session was empty")
        }
      } catch(error) {
        console.error(error)
        this.error_message = 'Error fetching session.  Trying reloading page.'
      }
    },
    async getIdentities() {
      console.log("Fetching Identities")
      this.status_message = 'Fetching Identities'
      const path = process.env.VUE_APP_API_URI + "/get-ss-identities"
      let identities = {}
      try {
        const res = await this.axios.get(path, { withCredentials: true }) 
        console.log(res)
        if (res.data.identities) {
          console.log('saving identities')
          identities = res.data.identities
          //this.$store.commit("set_roles_water_systems", res.data.water_systems)
        } else {
          console.log("no identities found.")
        }
      } catch(error) {
        console.error(error)
        this.error_message = 'Error fetching Identities.  Trying reloading page.'
      }
      return identities
    },
    async getPWS(sdwis_id) {
      this.status_message = 'Fetching Water System List'
      console.log("Fetching Water Systems")
      const path = process.env.VUE_APP_API_URI + "/get-pws-for-user"
      try {
        const res = await this.axios.get(
          path, { withCredentials: true, params: { sdwis_id: sdwis_id} }
        )
        console.log(res)
        if (res.data.water_systems) {
          console.log('saving water system list')
          console.log(res.data.water_systems)
          await this.$store.commit("set_roles_water_systems", res.data.water_systems)
        } else {
          console.log("no water systems")
        }
      } catch(error) {
        console.error(error)
        this.error_message = 'Error fetching Water Systems.  Trying reloading page.'
      }
    },
    async setDefaultPWS() {
      this.status_message = 'Setting Default Water System'
      if (this.signed_in) {
        const user_id = this.$store.state.user.user_id
        const pws_key = user_id + '_pws'
        console.log('PWS KEY')
        console.log(pws_key)
        const pws = await idb.get(pws_key)
        console.log(pws)
        let df_pws = pws ? pws : this.$store.getters.default_pws
        console.log('DF PWS')
        console.log(df_pws)
        await this.$store.commit('set_pws', df_pws)
        await this.$store.commit('set_pws_id', df_pws.pws_id)
      }
    },
    async checkSigningKey() {
      this.status_message = 'Checking Signature Key'
      console.log('store user')
      console.log(this.$store.state.user)
      if (this.$store.state.user) {
        const user_id = this.$store.state.user.user_id
        const key_name = user_id + '_signing_key'
        const signing_key = await idb.get(key_name)
        if (! signing_key) {
          console.log('generating Key')
          try {
            const wrapped_key = await this.generateKey(key_name)
            await idb.set(key_name, wrapped_key)
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    async generateKey() {
      const key = await window.crypto.subtle.generateKey(
          {
            name: "ECDSA",
            namedCurve: "P-256" //can be "P-256", "P-384", or "P-521"
          },
          false, //whether the key is extractable (i.e. can be used in exportKey)
          ["sign", "verify"] //can be any combination of "deriveKey" and "deriveBits"
      )
      const current_datetime = new Date()
      const formatted_date =
        current_datetime.getFullYear() +
        "-" +
        (current_datetime.getMonth() + 1) +
        "-" +
        current_datetime.getDate() +
        " " +
        current_datetime.getHours() +
        ":" +
        current_datetime.getMinutes() +
        ":" +
        current_datetime.getSeconds()
      const wrapped_key = {
        //name: this.signame,
        dt_created: formatted_date,
        key: key
      }
      return wrapped_key
    },

  }

};
</script>
